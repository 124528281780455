<template>
  <div class="h-full">
    <div class="bottom_space rounded py-2 h-full bg-white">
      <!-- <div class="pt-1">
        <div class="pb-2" v-if="IsSuperAdmin">
          <button class="mr-3 text-sm border  hover:bg-info py-2.5 text-text1 rounded-md list_btn" :class="statusVal ? 'bg-info border-info' : 'bg-white border-gray-500'" @click="statusChip('active')">Active</button>
          <button class="mr-3 text-sm border  hover:bg-info py-2.5  text-text1 rounded-md list_btn" :class="!statusVal ? 'bg-info border-info' : 'bg-white border-gray-500'" @click="statusChip('inactive')">Inactive</button>
        </div>
      </div> -->
      <div class="bottom_space flex justify-between">
          <div class="mb-2 flex">
            <div class="max-w-max mr-4">
              <div class="select_button text-sm text-gray3">
              <SimpleDropdown :dropdownList="items" @callApiAsperSelectValue1="getLeaveDataAsPerPagination1"/>
              </div>
            </div>
            <div style="display:flex" class="search_customer_box  text-gray-500 bg-white border border-gray-500 rounded-md text-sm px-2 py-1 items-center h-11">
              <span class="mr-2 cursor-pointer"><img height="20px" width="20px" src="@/assets/images/svg/gray-search.svg" alt=""></span>
              <input class="focus:outline-none w-72" type="text" name="" placeholder="Search" id="custSearchInput"
                v-model="searchForLead"
                @input="getContactListDeboucne"
              >
              <span v-if="searchForLead.length > 0" class="cursor-pointer"><img @click="clearSearch()" height="12px" width="12px" src="@/assets/images/svg/clear-input.svg" alt="clearInput"></span>
            </div>
          </div>
          <!-- <div class="flex">
            <div v-if="IsSuperAdmin">
              <button class="list_top_card_btn bg-primary cta-text text-white py-3 px-5 font-bold rounded-3xl" @click="openBlogComponent()">Blog Components</button>
            </div>
            <div v-if="IsSuperAdmin">
              <button class="list_top_card_btn bg-primary cta-text text-white py-3 px-5 font-bold rounded-3xl" @click="addUserPopup('0')">Create Blog</button>
            </div>
          </div> -->
        </div>
      <div>
      <div class="w-full">
        <div class="progress-bar" v-if="searchLoader">
          <div class="progress-bar-value"></div>
        </div>
      </div>
      <div style="overflow-y: auto; white-space: nowrap;" class="table_containder " v-if="!mobileView">
        <div class="text-text2  min-w-min heaer_row border border-gray1 font-bold flex heading-5 px-3 py-3 items-center">
          <div class="id_col flex items-center p-2">
            <span class="cursor-pointer" @click="sortByColumn(0)">{{titleObj[0].title}} </span>
            <img v-if="titleObj[0].sortVal === 'DESC'" src="@/assets/images/svg/DESC.svg" /> 
            <img v-if="titleObj[0].sortVal === 'ASC'" src="@/assets/images/svg/ASC.svg" /> 
          </div>
          <div class="name_col  flex items-center p-2">
            <span class="cursor-pointer" @click="sortByColumn(1)">{{titleObj[1].title}}</span>
            <img class="pl-1" v-if="titleObj[1].sortVal === 'DESC'" src="@/assets/images/svg/DESC.svg" /> 
            <img class="pl-1" v-if="titleObj[1].sortVal === 'ASC'" src="@/assets/images/svg/ASC.svg" /> 
          </div>
          <div class="cell_col  flex items-center p-2">
            <span class="cursor-pointer" @click="sortByColumn(2)">{{titleObj[2].title}}</span>
            <img class="pl-1" v-if="titleObj[2].sortVal === 'DESC'" src="@/assets/images/svg/DESC.svg" /> 
            <img class="pl-1" v-if="titleObj[2].sortVal === 'ASC'" src="@/assets/images/svg/ASC.svg" /> 
          </div>
          <div class="cell_col  flex items-center p-2">
            <span class="cursor-pointer" @click="sortByColumn(3)">{{titleObj[3].title}}</span>
            <img class="pl-1" v-if="titleObj[3].sortVal === 'DESC'" src="@/assets/images/svg/DESC.svg" /> 
            <img class="pl-1" v-if="titleObj[3].sortVal === 'ASC'" src="@/assets/images/svg/ASC.svg" /> 
          </div>
          <div class="cell_col  flex items-center p-2">
            <span class="cursor-pointer" @click="sortByColumn(4)">{{titleObj[4].title}}</span>
            <img class="pl-1" v-if="titleObj[4].sortVal === 'DESC'" src="@/assets/images/svg/DESC.svg" /> 
            <img class="pl-1" v-if="titleObj[4].sortVal === 'ASC'" src="@/assets/images/svg/ASC.svg" /> 
          </div>
          <div class="cell_col  flex items-center p-2">
            <span class="cursor-pointer" @click="sortByColumn(5)">{{titleObj[5].title}}</span>
            <img class="pl-1" v-if="titleObj[5].sortVal === 'DESC'" src="@/assets/images/svg/DESC.svg" /> 
            <img class="pl-1" v-if="titleObj[5].sortVal === 'ASC'" src="@/assets/images/svg/ASC.svg" /> 
          </div>
        </div>
        <div v-for="(data, index) in leadList" :key="index" class="min-w-min group   flex border-r border-l border-b border-gray1  bg-white  text-sm px-3 py-2 items-center cursor-pointer hover:bg-gray-50">
          <div class="flex" @click="addUserPopup(data.leadId)">
          <div class="id_col p-2">
            <span class="heading-4  text-text2 font-medium">{{data.leadId}}</span>
          </div>
          <div class="name_col p-2">
            <!-- <span class="heading-4  text-primary font-medium cursor-pointer" @click="gotoPageDetail(data)">{{data.leadName}}</span> -->
            <span class="heading-4  text-primary font-medium cursor-pointer">{{data.leadName}}</span>
          </div>
          <div class="cell_col p-2">
            <span class="heading-4 text-text2" v-if="data.emailAddress !== ''">{{data.emailAddress }}</span>
            <span class="" v-else>--</span>
          </div>
          <div class="cell_col p-2">
            <span class="heading-4 text-tsext2" v-if="data.phoneNumber !== ''">{{data.phoneNumber | phone}}</span>
            <span class="" v-else>--</span>
          </div>
          <div class="cell_col p-2">
            <span class="heading-4 text-text2" v-if="data.companyName !== ''">{{data.companyName}}</span>
            <span class="" v-else>--</span>
          </div>
          <div class="cell_col p-2">
            <span class="heading-4 text-text2" v-if="data.addedDate !== ''">{{data.addedDate | dateTimeFilter}}</span>
            <span class="" v-else>--</span>
          </div>
          </div>
          <div class="p-2 absolute right-5" v-if="IsSuperAdmin">
            <div class="">
              <!-- <span class="px-1 pr-3 cursor-pointer" @click="addUserPopup(data.pageMasterId)">
                <i class="fas fa-edit text-primary h-5 w-5" ></i>
              </span> -->
              <!-- <span class="px-1 pr-3 cursor-pointer" @click="addUserPopup(data.projectDetailId)">
                <i class="fa-solid fa-ellipsis-vertical text-text2 h-5 w-5"></i>
              </span> -->
              <!-- <span class="ml-2 mr-2 p-1 text-gray4  heading-1 cursor-pointer"  @click.stop.passive="opendata(data.leadId)">
                <i class="fas fa-ellipsis-v"></i>
              </span> -->
              <!-- <div>
                <div v-click-outside="closeCopyDeleteMenu">
                  <div v-if="data.isOpen" class=" bg-white absolute z-10 right-3 shadow-2xl rounded -top-7">
                    <div style="max-width: fit-content;" class="">
                      <div class="p-2 text-text1 ">
                        <div class="px-4 py-3 cursor-pointer hover:bg-red-50" @click="deletePage(data)">
                          <span class="heading-4 py-1 text-error">Delete Blog</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
      <div class="layout my-2" v-if="leadList.length === 0">
        <div class="flex p-3 text-sm text-primary bg-blue-100 rounded-lg  items-center" role="alert">
          <svg aria-hidden="true" class="flex-shrink-0 inline w-6 h-6 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
          <span class="sr-only">Info</span>
          <div>
            <p class=" font-medium heading-4">No record found.</p>
          </div>
        </div>
      </div>
    </div>
    <div class="pt-2">
      <Pagination
        :totalDataCount="totalEmpCount"
        :resetPagination="resetPaginationValues"
        @callApiAsPerPagination="getCustomerDataFromPagination"/>
    </div>
  </div>
  <!-- <div v-if="deleteSpecPage" class="popup_overlay">
    <div style="height: 120px; width: 500px;" class="custom_dialog">
      <div class="text-text1 px-4 py-3 flex justify-between items-center">
        <span class="heading-3">Are You Sure ?</span>
        <div class="flex justify-end">
          <button class="bg-error text-white shadow mr-2 rounded-full px-5 py-1.5" @click="deleteSpecPage = false">No</button>
          <button class="bg-primary shadow text-white rounded-full px-5 py-1.5" @click="removePage()">Yes</button>
        </div>
      </div>
      <hr>
      <div class="p-4">
        <p class="text-gray3 heading-4">Once delete cannot be undone !</p>
      </div>
    </div>
  </div> -->
</div>
</template>
<script>
import HRMAPI from '@/View/CMS/api/HRM.js'
import SimpleDropdown from '@/View/components/simpleDropdown.vue'
// import Pagination from '@/View/components/pagination.vue'
import Pagination from '@/View/components/pagination.vue'
import {FilterPermissions} from '@/utils/Permissions.js'
import deboucneMixin from '@/mixins/debounce.js'
export default {
  components: {
    Pagination,
    SimpleDropdown,
  },
  mixins: [deboucneMixin],
  data () {
    return {
      lastBuildDate: '',
      showError: false,
      buildSuccess: false,
      showLoader: false,
      specifiSelDetail: [],
      deleteSpecPage: false,
      IsSuperAdmin: false,
      showDetailPopup: false,
      userDetailValue: [],
      mobileView: false,
      istrue: true,
      userDetailId: 0,
      filterApplied: false,
      statusVal: true,
      navigationDialog: false,
      isFilterApplied: false,
      searchLoader: false,
      resetPaginationValues: false,
      showCustomerAddDialog: false,
      searchForLead: '',
      titleObj: [
        {title: 'Lead Id', icon: '', sortName: 'leadId', sortVal: ''},
        {title: 'Lead Name', icon: '', sortName: 'leadName', sortVal: ''},
        {title: 'Email Address', icon: '', sortName: 'emailAddress', sortVal: ''},
        {title: 'Phone Number', icon: '', sortName: 'phoneNumber', sortVal: ''},
        {title: 'Company Name', icon: '', sortName: 'companyName', sortVal: ''},
        {title: 'Added Date', icon: 'fas fa-sort-amount-up', sortName: 'addedDate', sortVal: 'DESC'}
    ],
      cancelFilter: {
        selectedBranch: [],
        selectedDepartment: []
      },
      filterObject: {
        start: 0,
        length: 20,
        searchVal: '',
        sortBy: 'addedDate',
        sortOrder: 'DESC',
        projectDetailId: 0
      },
      totalEmpCount: 0,
      paginationLength: 1,
      filterCol: 'text-gray-400',
      page: 1,
      items: [20, 25, 30, 50, 100],
      limitResData: 20,
      paginationData: 20,
      leadList: [],
      canAdd: FilterPermissions('customer_account__add_new')
    }
  },
  created() {
    window.addEventListener("resize", this.resizeWindowHandler);
  },
  mounted () {
    this.filterObject.projectDetailId = parseInt(this.$route.params.projectId)
    let Isadmin =  JSON.parse(localStorage.getItem('employeeData1'))
    this.IsSuperAdmin = Isadmin.isSuperAdmin
    localStorage.setItem('projectId', parseInt(this.$route.params.projectId))
    console.log(' parseInt(this.$route.params.projectId) 1',  parseInt(this.$route.params.projectId))
    this.resizeWindowHandler();
    document.title = 'Lead list'
    this.getContactListDeboucne = this.debounce(function () {
      this.resetPaginationValues = true
    }, 500)
    this.getLeadList()
  },
  watch: {
    searchForLead: {
      handler () {
        if (this.searchForLead === null) {
          this.searchForLead = ''
        }
      }
    },
    titleObj: {
      handler () {
        console.log('titleObj 123', this.titleObj)
      }
    },
    filterApplied: {
      handler () {
        console.log('filterApplied 123', this.filterApplied)
        if (this.filterApplied === true) {
          this.filterCol = 'text-primary'
        } else {
          this.filterCol = 'text-success'
        }
      }
    },
    paginationData: {
      handler () {
        this.filterObject.length = this.paginationData
        this.showlistLoader = true
        this.getLeadList(this.showlistLoader)
      },
      deep: true
    }
  },
  methods: {
    opendata (data) {
      console.log(data)
      this.leadList.forEach(element => {
        if ( element.leadId === data) {
          element.isOpen = true
        } else {
          element.isOpen = false
        }
      });
    },
    closeCopyDeleteMenu () {
      for (let index = 0; index < this.leadList.length; index++) {
        this.leadList[index].isOpen = false
      }
    },
    resizeWindowHandler() {
      if (window.innerWidth < 684) {
        this.mobileView = true;
      } else {
        this.mobileView = false;
      }
    },
    openBlogComponent () {
      this.$router.push({name: 'BlogComponent', params: {projectId: parseInt(this.$route.params.projectId)}})
    },
    addUserPopup (index) {
      this.$router.push({name: 'LeadDetail', params: {projectId: parseInt(this.$route.params.projectId), pageMasterId: index}})
    },
    deletePage (data) {
      console.log('data', data)
      this.deleteSpecPage = true
      this.specifiSelDetail = data
    },
    removePage () {
      this.deleteSpecPage = false
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      HRMAPI.BlogPageDeleteApi(
        this.specifiSelDetail.projectDetailId,
        this.specifiSelDetail.leadId,
        response => {
          console.log("response 1233", response.Data)
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.getLeadList()
        },
        error => {
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
    clearSearch () {
      this.searchForLead = ''
      this.searchLoader = false
      this.getLeadList()
    },
    getCustomerDataFromPagination (data) {
      this.filterObject.start = data.offset
      this.filterObject.length = data.limit
      let showlistLoader = false
      if (data.offset !== 0) {
        showlistLoader = true
      }
      this.getLeadList(showlistLoader)
    },
    sortByColumn (index) {
      for (let i = 0; i < this.titleObj.length; i++) {
        if (i === index) {
          if (this.titleObj[i].icon === '') {
            this.titleObj[i].icon = 'fas fa-sort-amount-down'
            this.titleObj[i].sortVal = 'DESC'
            this.filterObject.sortOrder = 'DESC'
          } else if (this.titleObj[i].icon === 'fas fa-sort-amount-down') {
            this.titleObj[i].icon = 'fas fa-sort-amount-up'
            this.titleObj[i].sortVal = 'ASC'
            this.filterObject.sortOrder = 'ASC'
          } else {
            this.titleObj[i].icon = 'fas fa-sort-amount-down'
            this.filterObject.sortOrder = 'DESC'
            this.titleObj[i].sortVal = 'DESC'
          }
        } else {
          this.titleObj[i].icon = ''
          this.titleObj[i].sortVal = ''
        }
      }
      this.filterObject.sortBy = this.titleObj[index].sortName
      this.getLeadList(true)
    },
    redirectToDetail (employeeId) {
      if (FilterPermissions('employee__detail_view')) {
        this.$router.push({name: 'EmployeeDetail', params: {employeeId: employeeId}})
      }
    },
    toTop () {
      this.$vuetify.goTo(0)
    },
    statusChip (val) {
      console.log(val)
      if (val === 'active') {
        this.statusVal = true
        this.filterObject.isActive = true
      } else if (val === 'inactive') {
        this.statusVal = false
        this.filterObject.isActive = false
      }
      this.getLeadList()
    },
    getLeaveDataAsPerPagination1 (data) {
      this.filterObject.length = data
      this.getLeadList()
    },
    getLeadList () {
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      HRMAPI.LeadListApi(
        this.filterObject.start,
        this.filterObject.length,
        this.searchForLead,
        this.filterObject.sortBy,
        this.filterObject.sortOrder,
        this.filterObject.projectDetailId,
        response => {
          console.log("response", response.Data)
          this.resetPaginationValues = false
          let leadList = response.Data.tableRow === null ? [] : response.Data.tableRow
          for (let index = 0; index < leadList.length; index++) {
            leadList[index].isOpen = false
          }
          this.leadList = leadList
          this.totalEmpCount = response.Data.count
          console.log('count', this.totalEmpCount)
          this.searchLoader = false
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        error => {
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    }
  },
  beforeDestroy () {
    localStorage.removeItem('filterDataOfEmployee')
    this.$root.$off('Filter')
    this.$root.$off('Filters')
  }
}
</script>
<style scoped>
.table_containder {
  padding: 1px;
}
.name_col {
  min-width: 500px !important;
  width: 500px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.id_col {
  min-width: 120px !important;
  width: 120px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.cell_col {
  min-width: 280px !important;
  width: 280px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.list_btn {
  min-width: 77px !important;
  max-width: 77px;
}
.select_btn {
  min-width: 77px !important;
  max-width: 77px;
}
.select_btn:focus-visible {
  outline: none;
}
.search_customer_box {
  min-width: 335px !important;
  max-width: 335px !important;
}
.list_top_card_btn {
  width: 183px !important;
  margin-right: 15px !important;
}
.row_user_svg {
  height: 36px;
  width: 36px;
}
.navbar-open {
  transform: translateX(0%);
}
.navbar-close {
  transform: translateX(-100%);
}
.demo-container {
  width: 300px;
  margin: auto;
}

.progress-bar {
  height: 2px;
  background-color: rgb(18,149,186);
  width: 100%;
  overflow: hidden;
}

.progress-bar-value {
  width: 100%;
  height: 100%;
  background-color: rgb(121, 200, 221);
  animation: indeterminateAnimation 2s infinite linear;
  transform-origin: 0% 50%;
}

@keyframes indeterminateAnimation {
  0% {
    transform:  translateX(0) scaleX(0);
  }
  40% {
    transform:  translateX(0) scaleX(0.4);
  }
  100% {
    transform:  translateX(100%) scaleX(0.5);
  }
}
::placeholder {
  font-size: 16px;
}
.lableWidth {
  width: 105px;
}
.dotwidth {
  width: 15px;
}
.loader {
  border: 3px solid #f3f3f3;
  border-radius: 50%;
  border-top: 3px solid #307dea;
  width: 27px;
  height: 27px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
